import { FC, ReactNode } from 'react'

import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'

import { reorder } from './utils'

export interface DragAndDropFolder {
  id: number
  name: string
  subFolders?: DragAndDropFolder[]
}

interface DragAndDropFoldersProps {
  droppableId: string
  folders: DragAndDropFolder[]
  children: ReactNode
  onDragEnd: (param?: any) => void // Type will change on next update
}

export const handleDragEnd = (
  result: DropResult,
  folders: DragAndDropFolder[],
  onDragEnd: (param?: any) => void,
) => {
  // The item was dropped outside the list
  if (!result.destination) {
    return
  }

  const startIndex: number = result.source.index
  const endIndex: number = result.destination.index

  const updatedOrderArray = reorder(folders, startIndex, endIndex)

  // Update your UI state with reordered items
  onDragEnd(updatedOrderArray)
}

const DragAndDropFolders: FC<DragAndDropFoldersProps> = ({
  droppableId,
  folders,
  children,
  onDragEnd,
}) => {
  return (
    <DragDropContext
      onDragEnd={(result) => handleDragEnd(result, folders, onDragEnd)}
    >
      <Droppable droppableId={droppableId}>
        {(provided, _snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DragAndDropFolders
