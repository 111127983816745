import { FC, ReactElement } from 'react'

import { Draggable } from 'react-beautiful-dnd'

import { DragAndDropFolder } from './DragAndDropFolderList'
import { DragHandle as DragHandleComponent } from './DragHandle'
import Folder from './Folder'
import { getItemStyle } from './utils'

interface DraggableFolderProps {
  folder: DragAndDropFolder
  children?: ReactElement
  index: number
  isSubFolder?: boolean
  disableDragAndDrop?: boolean
  grid?: number
  FolderTitleActions?: JSX.Element
}

const DraggableFolder: FC<DraggableFolderProps> = ({
  folder,
  children,
  index,
  isSubFolder = false,
  disableDragAndDrop = false,
  grid = 0,
  FolderTitleActions = null,
}) => {
  return (
    <Draggable
      key={folder?.id.toString()}
      draggableId={folder?.id.toString()}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
            grid,
          )}
        >
          <Folder
            isSubFolder={isSubFolder}
            folder={folder}
            forceOpen={disableDragAndDrop}
            DragHandle={
              <DragHandleComponent
                dragHandleProps={provided.dragHandleProps}
                disableDragAndDrop={disableDragAndDrop}
              />
            }
            FolderTitleActions={FolderTitleActions}
          >
            {children}
          </Folder>
        </div>
      )}
    </Draggable>
  )
}

export default DraggableFolder
